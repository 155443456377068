<template>
  <div>
    <template v-if="currentStep === 1">
      <div class="prompt-debug-list">
        <a-row type="flex" justify="space-between">
          <a-input-search
            class="search-input"
            allowClear
            placeholder="请输入prompt名称查询"
            v-model="form.promptName"
            @search="getTableData(true)"
          ></a-input-search>

          <a-button type="primary" @click="addPrompt">新增prompt</a-button>
        </a-row>
        <div class="data-table">
          <a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false" row-key="id">
            <span slot="promptDesc" slot-scope="text, record">
              <a-tooltip :title="record.promptDesc">
                <span>{{ record.promptDesc }}</span>
              </a-tooltip>
            </span>
            <div slot="operation" slot-scope="text, record">
              <a-space>
                <a-button type="link" @click="handleToDebug(record.id)">查看</a-button>
              </a-space>
            </div>
          </a-table>
          <base-pagination
            :current-page="pagination.current"
            :page-size="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
          />
        </div>
      </div>
    </template>
    <template v-if="currentStep === 2">
      <PromptDebug ref="PromptDebug" @back="handleBack" />
    </template>
  </div>
</template>

<script>
import api from '@/api/AIGC';
import PromptDebug from './promptDebug.vue';

export default {
  data() {
    const columns = [
      {
        title: 'prompt名称',
        dataIndex: 'promptName',
      },
      {
        title: '模型',
        dataIndex: 'aiModel',
      },
      {
        title: '创建人',
        dataIndex: 'creator',
      },
      {
        title: '创建时间',
        dataIndex: 'ctime',
      },
      {
        title: 'prompt描述',
        scopedSlots: { customRender: 'promptDesc' },
        ellipsis: true,
      },
      {
        align: 'center',
        title: '操作',
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    return {
      columns,
      loading: false,
      form: {
        promptName: undefined,
      },
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      currentStep: 1,
    };
  },
  components: { PromptDebug },
  mounted() {
    this.getTableData();
  },
  methods: {
    getTableData(isFirst) {
      this.loading = true;
      const params = {
        ...this.form,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getPromptDebugList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    handleToDebug(id) {
      this.currentStep = 2;
      this.$nextTick(() => {
        this.$refs.PromptDebug.handleInit(id);
      });
    },
    addPrompt() {
      this.currentStep = 2;
      this.$nextTick(() => {
        this.$refs.PromptDebug.getAllPromptList();
      });
    },
    handleBack() {
      this.currentStep = 1;
      this.getTableData()
    },
  },
};
</script>

<style scoped lang="less">
.search-input {
  width: 200px;
  margin-bottom: 15px;
}
</style>
